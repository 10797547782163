<template>
  <FocusLoop>
    <div ref="modal_page" class="modal_page center">
      <form
        @submit.prevent="validate"
        ref="modal_window"
        class="account_modal d-flex d-col space-between"
      >
        <div class="d-flex space-between align-center">
          <div class="font-18">{{ $t("modal.account.account") }}</div>
          <s-icon @click.native="closeModal" color="grey">close</s-icon>
        </div>
        <div class="center mt-10 missingText red--text font-14">
          {{ message }}
        </div>
        <form ref="formAvatar" class="none">
          <input type="file" @change="upload" name="avatar" ref="fileAvatar" />
        </form>
        <section class="center">
          <div class="relative">
            <div class="profile">
              <img class="pic" v-if="avatar" :src="avatarUrl" />
              <s-icon height="100" v-if="!avatar">account</s-icon>
              <pulse-loader
                :loading="load"
                color="white"
                size="10px"
                class="absolute"
              ></pulse-loader>
            </div>
            <button
              type="button"
              class="white round camera center opacity-60"
              @click="openPicker"
            >
              <s-icon class="">camera</s-icon>
            </button>
          </div>
        </section>
        <div class="grid align-center mt-20">
          <div class="font-14 opacity-60">{{ $t("modal.account.email") }}</div>
          <s-text-field v-model="email" disabled height="30"></s-text-field>
          <div class="font-14 opacity-60">
            {{ $t("modal.account.first_name") }}
          </div>
          <s-text-field v-model="firstName_input" height="30"></s-text-field>
          <div class="font-14 opacity-60">
            {{ $t("modal.account.last_name") }}
          </div>
          <s-text-field v-model="lastName_input" height="30"></s-text-field>
          <div class="font-14 opacity-60">
            {{ $t("modal.account.timezone") }}
          </div>
          <s-timezone v-model="timezone"></s-timezone>

          <div class="font-14 opacity-60">
            {{ $t("modal.account.current_password") }}
          </div>
          <s-text-field
            type="password"
            v-model="password"
            password
            height="30"
          ></s-text-field>
          <div class="font-14 opacity-60">
            {{ $t("modal.account.new_password") }}
          </div>
          <div>
            <s-text-field
              v-model="newPassword"
              bar
              password
              height="30"
              type="password"
            ></s-text-field>
          </div>
          <div class="font-14 opacity-60">
            {{ $t("modal.account.confirm_password") }}
          </div>
          <s-text-field
            v-model="confirmPassword"
            type="password"
            password
            height="30"
          ></s-text-field>

          <div class="font-14 opacity-60">
            {{ $t("modal.account.region") }}
          </div>
          <s-dropdown
            :height="30"
            class="w100 item"
            v-model="preferedRegion"
            :items="servers"
          ></s-dropdown>

          <div class="font-14 opacity-60">
            {{ $t("modal.account.enable_tooltip") }}
          </div>
          <s-checkbox
            @click.native="check = !check"
            v-model="check"
          ></s-checkbox>
        </div>

        <div class="d-flex justify-end mt-40">
          <s-btn
            type="button"
            @click.native="closeModal"
            height="36"
            class="mr-10"
            >{{ $t("modal.cancel") }}</s-btn
          >
          <s-btn type="submit" height="36" class="green">{{
            $t("modal.save")
          }}</s-btn>
        </div>
      </form>
    </div>
  </FocusLoop>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap";
export default {
  data() {
    return {
      firstName_input: "",
      lastName_input: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
      message: "",
      timezone: "",
      check: true,
      preferedRegion: "",
      load: false,
    };
  },
  methods: {
    async post() {
      let data = {
        firstName: this.firstName_input,
        lastName: this.lastName_input,
        timezone: this.timezone,
        tooltips: this.check == true ? "yes" : "no",
        preferredRegion: this.preferedRegion.value,
      };

      if (this.password.length) {
        data.oldPassword = this.password;
        data.newPassword1 = this.newPassword;
        data.newPassword2 = this.confirmPassword;
      }
      this.$store.commit("user/tip", this.check);
      let response = await this.$store.dispatch("user/setProfile", data);
      if (response.status == "ok") return this.closeModal();
      let message = this.$t("server." + response.errorCode);
      if (response.status == "error") this.message = message;
    },
    closeModal() {
      gsap.to(this.$refs.modal_page, {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
      });
      gsap.to(this.$refs.modal_window, {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
        onComplete: () => this.$store.commit("modals/accountModal", false),
      });
    },
    validatename() {
      var exr = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/;
      var first = this.firstName_input.match(exr);
      var last = this.lastName_input.match(exr);
      let code = "";
      if (!last) code = "invalid_lastname";
      if (!first) code = "invalid_firstname";
      if (this.lastName_input.length < 2) code = "min_last_name";
      if (this.firstName_input.length < 2) code = "min_first_name";
      if (this.lastName_input.length == 0) code = "enter_last_name";
      if (this.firstName_input.length == 0) code = "enter_first_name";
      if (!code) return true;
      this.message = this.$t("server." + code);
    },
    validate() {
      this.message = "";
      if (!this.validatename()) return;
      if (this.password.length > 0) {
        if (this.newPassword != this.confirmPassword) {
          this.message = this.$t("modal.account.password_no_match");
          return;
        }
        if (this.newPassword.length < 8) {
          this.message = this.$t("modal.account.password_min_8_char");
          return;
        }
      }
      this.post();
    },
    openPicker() {
      this.$refs.fileAvatar.click();
    },
    async upload(data) {
      let formData = new FormData(this.$refs.formAvatar);
      this.load = true;
      await this.$store.dispatch("user/setProfilePicture", formData);
      await this.$store.dispatch("user/getUserInformation");
      this.load = false;
    },
  },
  computed: {
    avatarUrl() {
      return process.env.VUE_APP_URL + "/api/avatar/" + this.avatar;
    },
    servers() {
      let data = [
        { display: this.$t("languages.na"), value: "na" },
        { display: this.$t("languages.eu"), value: "eu" },
        // { display: this.$t("languages.ca"), value: "ca" },
        // { display: this.$t("languages.ot"), value: "other" },
      ];
      let ob = data.findIndex((el) => el.value == this.preferredRegion);
      data[ob].selected = true;
      return data;
    },
    ...mapState("user", [
      "email",
      "firstName",
      "lastName",
      "preferredRegion",
      "avatar",
      "tip",
    ]),
  },
  mounted() {
    this.firstName_input = this.firstName;
    this.lastName_input = this.lastName;
    this.check = this.tip;

    gsap.from(this.$refs.modal_page, {
      opacity: 0,
      duration: 0.3,
      ease: "power2.out",
    });

    gsap.from(this.$refs.modal_window, {
      scale: 0.7,
      duration: 0.3,
      ease: "power2.out",
    });
  },
};
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.account_modal {
  width: 450px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
}

.grid {
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 15px;
}

.strength_container {
  grid-column-gap: 4px;
  min-height: 2px;
  margin-top: 5px;
}

.strength {
  width: 72px;
  height: 2px;
  grid-column-gap: 5px;
}

.profile {
  background-color: grey;
  height: 100px;
  width: 100px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pic {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.camera {
  position: absolute;
  right: 0px;
  bottom: 4px;
  padding: 2px;
  transition: all 0.2s ease;
}

.camera:hover {
  transform: scale(1.1);
  opacity: 1;
}

.camera:focus {
  background-color: #ffffff;
  transform: scale(1.1);
  opacity: 1;
}

.red {
  background-color: #df5151;
}

.orange {
  background-color: #df9851;
}

.green {
  background-color: #55af6d;
}

.blue {
  background-color: #517cdf;
}
@media only screen and (max-width: 576px) {
  .account_modal {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>