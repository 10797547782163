<template>
  <div class="pb-60">
    <Account v-if="account" />
    <div class="top_toolabr noselect">
      <div class="logo_container">
        <button @click="home" class="center mr-10 logoButton">
          <img
            class="logo pointer"
            :title="tip && $t('toolbars.topToolbar.home')"
            src="/img/static/logo.svg"
            alt="logo"
          />
        </button>
        <div class="mobile_name grow">
          <div>secur</div>
          <div class="bold">Meet</div>
        </div>
        <LanguageSelector v-if="$mq === 'sm'" />
        <button @click="toggleDrawer" class="mobile_chevron">
          <s-icon color="green">menu</s-icon>
        </button>

        <img
          v-if="email && sidebar"
          :title="tip && $t('toolbars.topToolbar.sidebar_close')"
          class="chevron_double green_svg pointer"
          src="/img/icons/chevron-double-left.svg"
          alt="chevron_double"
          @click="toggle"
        />
        <img
          v-if="email && !sidebar"
          :title="tip && $t('toolbars.topToolbar.sidebar_open')"
          class="chevron_double green_svg pointer"
          src="/img/icons/chevron-double-right.svg"
          alt="chevron_double"
          @click="toggle"
        />
      </div>
      <LanguageSelector
        v-if="$mq !== 'sm'"
        class="ml-auto"
        :title="tip && $t('toolbars.topToolbar.language')"
      />
      <button
        v-if="email"
        class="name pointer"
        @click="toggleMenu"
        v-hotkey="{ esc: closeMenu }"
        :title="tip && $t('toolbars.topToolbar.account')"
      >
        <section class="center ml-20">
          <div class="relative">
            <div class="profile">
              <img class="pic" v-if="avatar" :src="avatarUrl" />
              <s-icon height="30" v-if="!avatar">account</s-icon>
            </div>
          </div>
        </section>
        <div class="user_name">{{ firstName }} {{ lastName }}</div>
        <img
          class="chev_down white_svg"
          src="/img/icons/chevron-down.svg"
          alt="logo"
        />
      </button>
      <section v-if="!email" class="name">
        <a class="d-flex white--text ml-10" href="https://cloud.securmeet.com/login">
          <s-icon width="16" color="white">account</s-icon>
          <div class="mx-5 capital">{{ $t("registration.login") }}</div>
        </a>
        <a class="font-14 d-flex center ml-10" href="https://cloud.securmeet.com/registration">
          <div class="white--text login">{{ $t("login.signup") }}</div>
        </a>
      </section>
    </div>
  </div>
</template>

<script>
import Menu from "./AccountDropDown";
import Account from "@/components/Modals/Account";
import LanguageSelector from "@/components/Atoms/LanguageSelector";
import { mapState } from "vuex";
import dayjs from "dayjs";

export default {
  components: {
    Menu,
    Account,
    LanguageSelector,
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    toggle() {
      this.$store.commit("toolbars/toggleSidebar");
    },
    toggleDrawer() {
      this.$store.commit("toolbars/toggleDrawer");
    },
    home() {
      let loggedin = this.email && this.$route.path != "/dashboard";
      let loggedout = !this.email && this.$route.path != "/";
      if (loggedin) return this.$router.push("/dashboard");
      if (loggedout) return this.$router.push("/");
    },
    toggleMenu() {
      this.menu = !this.menu;
    },
    closeMenu() {
      this.menu = false;
    },
  },
  computed: {
    avatarUrl() {
      return process.env.VUE_APP_URL + "/api/avatar/" + this.avatar;
    },

    ...mapState("user", [
      "email",
      "firstName",
      "lastName",
      "lang",
      "avatar",
      "tip",
    ]),
    ...mapState("modals", ["account", "billing"]),
    ...mapState("toolbars", ["sidebar"]),
  },
  watch: {
    immediate: true,
    account(val) {
      this.closeMenu();
    },
    billing(val) {
      this.closeMenu();
    },
    $mq(val) {
      if (val == "sm") this.closeMenu();
    },
  },
};
</script>

<style scoped lang="less">
* {
  transition: all ease 0.2s;
}

.top_toolabr {
  display: flex;
  width: 100%;
  height: 60px;
  background-color: #1d2535;
  position: fixed;
  z-index: 4;
}

.logo_container {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 0s;
  width: 90px;
  height: 60px;
  background-color: var(--d2-blue);
}

.logo {
  height: 43px;
  width: 40px;
  min-width: 40px;
}

.logoButton:focus,
.logoButton:hover {
  background-color: #00000000;
  transform: scale(1.05);
  filter: brightness(1.2);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
}

.chevron_double {
  min-width: 24px;
}

.chev_down {
  margin-right: 10px;
  height: 21px;
  width: 21px;
  min-width: 21px;
}

.main {
  flex-grow: 1;
  display: flex;
  align-items: center;
  color: var(--green);
  font-size: 14px;
  margin-left: 13px;
}
.name {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 197px;
  background-color: #0000004d;
  font-size: 14px;
  color: var(--white);
}

.name:hover {
  background-color: #ffffff10;
}

.name:focus {
  background-color: #cccccc10;
}

.user_name {
  margin-right: 10px;
  margin-left: 10px;
  text-align: end;
  width: 100%;
}

.alarm {
  margin-right: 5px;
  min-width: 24px;
}

.login {
  border: solid 1px #42515e;
  border-radius: 5px;
  padding: 7px;
  background-color: var(--green);
}

.mobile_name {
  display: none;
  width: 114px;
  height: 32px;
  font-size: 24px;
  font-weight: 300;
  color: var(--green);
}

.mobile_chevron {
  color: var(--white);
  margin-left: auto;
  display: none;
  margin-right: 20px;
}

.profile {
  background-color: grey;
  height: 30px;
  width: 30px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pic {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 576px) {
  .main {
    display: none;
  }

  .name {
    display: none;
  }

  .chevron_double {
    display: none;
  }

  .logo_container {
    width: 100%;
    justify-content: flex-start;
    padding-left: 10px;
    transition: all ease 0.2s;
  }

  .mobile_name {
    display: flex;
  }

  .mobile_chevron {
    color: var(--white);
    margin-left: auto;
    display: flex;
  }
}
</style>
